import React from "react"
import styled from "styled-components"
import useMobileDetect from "use-mobile-detect-hook"
import { Carousel } from "react-responsive-carousel"
import ShopifyBtn from "./shopify-btn"
import "react-responsive-carousel/lib/styles/carousel.min.css"

import Air1 from "../images/Boomsy-Air.jpg"
import Air2 from "../images/Boomsy-Air2.jpg"
import Pheme1 from "../images/Pheme2.jpg"
import Pheme2 from "../images/Boomsy-Pheme2.jpg"
import Bold1 from "../images/Bold2.jpg"
import Bold2 from "../images/Boomsy-Bold2.jpg"

const Section = styled.section`
  background: #fff5f1;
  h2 {
    text-transform: uppercase;
    text-align: center;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 6rem;
  }
  h3 {
    text-transform: uppercase;
    font-size: 2rem;
  }
  p {
    font-size: 1.8rem;
  }
  .boom-level {
    text-transform: uppercase;
    font-weight: 300;
    margin-top: 2rem;
    color: #e8b795;
    font-size: 1.2rem;
  }
  .stars {
    margin-bottom: 2rem;
    font-size: 3rem;
    color: #e8b795;
  }
  .swap-on-hover {
    position: relative;
    margin: 0 auto;
    max-width: 300px;
    min-height: 300px;
  }
  .swap-on-hover img {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 300px;
    height: 300px;
  }
  .swap-on-hover .swap-on-hover__front-image {
    z-index: 998;
    transition: opacity 0.3s linear;
    cursor: pointer;
  }
  .swap-on-hover:hover > .swap-on-hover__front-image {
    opacity: 0;
  }
  .swap-on-hover .swap-on-hover__back-image {
    border: 4px solid #e8b795;
  }
  .carousel .slide {
    background: transparent;
  }
  .carousel .control-dots .dot {
    width: 5px;
    height: 5px;
    background: var(--light-black);
    opacity: 0.3;
    box-shadow: none;
  }
  .carousel .control-dots .dot.selected {
    background: #e8b795;
    opacity: 1;
  }
  .carousel .control-dots .dot {
    margin: 0 4px;
  }
  .carousel img {
    max-width: 300px;
  }

  @media (max-width: 768px) {
    .grid-2x {
      padding-bottom: 4rem;
    }
    .grid-2x:last-of-type {
      padding-bottom: 0rem;
    }
  }
`

const ProductDetail = () => {
  const detectMobile = useMobileDetect()
  return (
    <Section id="lashes">
      <div className="container">
        <h2>
          Os nossos <span style={{ fontWeight: 700 }}>modelos</span>
        </h2>
        <div className="grid-2x" style={{ alignItems: "normal" }}>
          <div className="mobile-first">
            {detectMobile.isMobile() ? (
              <Carousel showStatus={false} showThumbs={false}>
                <div>
                  <img src={Air1} alt="Boomsy Air 1" />
                </div>
                <div>
                  <img src={Air2} alt="Boomsy Air 2" />
                </div>
              </Carousel>
            ) : (
              <figure className="swap-on-hover">
                <img
                  className="swap-on-hover__front-image"
                  src={Air1}
                  alt="Boomsy Air 1"
                  loading="lazy"
                />
                <img
                  className="swap-on-hover__back-image"
                  src={Air2}
                  alt="Boomsy Air 2"
                  loading="lazy"
                />
              </figure>
            )}
          </div>
          <div>
            <h3>
              <span style={{ fontWeight: 300 }}>Boomsy</span> Air
            </h3>
            <div className="boom-level">Nível de Intensidade</div>
            <div className="stars">★☆☆☆☆</div>
            <p>
              <strong>Comprimento: </strong>11mm
              <br />
              <strong>Tamanho da Banda: </strong>28mm
              <br />
            </p>
            <p>
              As Pestanas <strong>Boomsy Air</strong> serão as tuas BFF do
              dia-a-dia. A sua missão? Realçar o teu look natural, especialmente
              se tiveres olhos pequenos.
            </p>
            <p>
              As Boomsy Air, graças à sua banda transparente, são super leves e
              confortáveis, perfeitas para as levar para o trabalho ou para
              qualquer lado.
            </p>
            <div>
              <ShopifyBtn
                id="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwNzA5Mjg3MDM2Nzg="
                className="btn-homepage"
                copy="Adicionar Kit"
              />
              <ShopifyBtn
                id="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMjk5MDcxNjUzNzQ="
                className="btn-secondary"
                copy="Adicionar Pestanas"
              />
            </div>
          </div>
        </div>
        <div className="grid-2x" style={{ alignItems: "normal" }}>
          <div>
            <h3>
              <span style={{ fontWeight: 300 }}>Boomsy</span> Pheme
            </h3>
            <div className="boom-level">Nível de Intensidade</div>
            <div className="stars">★★★☆☆</div>
            <p>
              <strong>Comprimento: </strong>8-13mm
              <br />
              <strong>Tamanho da Banda: </strong>32mm
              <br />
            </p>
            <p>
              As Pestanas <strong>Boomsy Pheme</strong> são o complemento ideal
              de um <em>look</em> clássico. Mais ousadas que as Boomsy Air, são
              perfeitas para dar alongamento e preencher as tuas pestanas.
            </p>
            <p>
              Recomendamos as Pheme para o dia a dia - se queres dar
              personalidade às tuas pestanas sem ficar “over the top”, esta
              deverá ser a tua escolha!
            </p>
            <div>
              <ShopifyBtn
                id="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwNzA5MjM0MjgwMzA="
                className="btn-homepage"
                copy="Adicionar Kit"
              />
              <ShopifyBtn
                id="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMjk5MTA5NjY0NjI="
                className="btn-secondary"
                copy="Adicionar Pestanas"
              />
            </div>
          </div>
          <div className="mobile-first">
            {detectMobile.isMobile() ? (
              <Carousel showStatus={false} showThumbs={false}>
                <div>
                  <img src={Pheme1} alt="Boomsy Pheme 1" />
                </div>
                <div>
                  <img src={Pheme2} alt="Boomsy Pheme 2" />
                </div>
              </Carousel>
            ) : (
              <figure className="swap-on-hover">
                <img
                  className="swap-on-hover__front-image"
                  src={Pheme1}
                  alt="Boomsy Pheme 1"
                  loading="lazy"
                />
                <img
                  className="swap-on-hover__back-image"
                  src={Pheme2}
                  alt="Boomsy Pheme 2"
                  loading="lazy"
                />
              </figure>
            )}
          </div>
        </div>
        <div className="grid-2x" style={{ alignItems: "normal" }}>
          <div className="mobile-first">
            {detectMobile.isMobile() ? (
              <Carousel showStatus={false} showThumbs={false}>
                <div>
                  <img src={Bold1} alt="Boomsy Bold 1" />
                </div>
                <div>
                  <img src={Bold2} alt="Boomsy Bold 2" />
                </div>
              </Carousel>
            ) : (
              <figure className="swap-on-hover">
                <img
                  className="swap-on-hover__front-image"
                  src={Bold1}
                  alt="Boomsy Bold 1"
                  loading="lazy"
                />
                <img
                  className="swap-on-hover__back-image"
                  src={Bold2}
                  alt="Boomsy Bold 2"
                  loading="lazy"
                />
              </figure>
            )}
          </div>
          <div>
            <h3>
              <span style={{ fontWeight: 300 }}>Boomsy</span> Bold
            </h3>
            <div className="boom-level">Nível de Intensidade</div>
            <div className="stars">★★★★★</div>
            <p>
              <strong>Comprimento: </strong>6-16mm
              <br />
              <strong>Tamanho da Banda: </strong>32mm
              <br />
            </p>
            <p>
              As Pestanas <strong>Boomsy Bold</strong> são perfeitas para aquele
              efeito BOOM! O seu volume não deixa ninguém indiferente.
            </p>
            <p>
              Seja numa saída à noite, seja no dia-a-dia, uma coisa é garantida:
              vais dar nas vistas com as nossas pestanas mais extravagantes!
            </p>
            <div>
              <ShopifyBtn
                id="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMzAwMTI4NzQ5NDI="
                className="btn-homepage"
                copy="Adicionar Kit"
              />
              <ShopifyBtn
                id="Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMjk3ODYxNTMxNTA="
                className="btn-secondary"
                copy="Adicionar Pestanas"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ProductDetail
