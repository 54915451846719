import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const Section = styled.section`
  background: var(--grey);
  .title {
    color: #e8b795;
    text-transform: uppercase;
    font-size: 3.6rem;
    font-weight: 700;
    margin-bottom: 6rem;
    span {
      color: #4f4f4f;
    }
    @media (max-width: 640px) {
      font-size: 3rem;
    }
  }
  h3 {
    font-family: "Tahu", sans-serif;
    font-size: 9.6rem;
    transform: rotate(-6deg);
    color: #e8b795;
    @media (max-width: 767px) {
      line-height: 8rem;
      margin: 6rem 0 4rem;
    }
  }
  .price {
    margin-top: -2.5rem;
    color: var(--light-black);
    font-size: 8.2rem;
    font-weight: 900;
    small {
      font-size: 2rem;
    }
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.8rem;
    max-width: 35ch;
    margin: 0 auto 1rem;
  }
  @media (max-width: 768px) {
    .grid-2x {
      grid-template-columns: 1fr;
    }
    .gatsby-image-wrapper {
      max-width: 400px;
      margin: 0 auto;
    }
  }
`

const starterkit = () => {
  return (
    <Section>
      <div className="container">
        <div className="title center">
          Experimenta as pestanas que <span>vão revolucionar a tua vida</span>
        </div>
        <div className="grid-2x">
          <div>
            <StaticImage
              src="../images/starter-kit.png"
              alt="Boomsy starter kit"
              placeholder="tracedSVG"
            />
          </div>
          <div className="center">
            <h3>Starter Kit</h3>
            <div className="price">
              35<small>€</small>
            </div>
            <p>
              Inclui um modelo de{" "}
              <em>Pestanas Magnéticas Boomsy (à tua escolha)</em> e um{" "}
              <em>Eyeliner Magnético Boomsy</em>
            </p>
            <div className="center">
              <Link to="/loja/" className="btn">
                Visitar Loja &gt;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default starterkit
