import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

const Section = styled.section`
  background: var(--grey);
  font-size: 1.6rem;
  p {
    line-height: 2.4rem;
  }
  h1,
  h2 {
    font-family: "tahu";
    font-size: 4.8rem;
    padding-bottom: 2.4rem;
    text-transform: initial;
  }
`

const ProductGroup = () => {
  return (
    <Section id="products">
      <div className="container">
        <div className="grid-2x">
          <div className="center" style={{ padding: "4rem" }}>
            <StaticImage
              src="../images/eyeliner-magnetico-boomsy.png"
              alt="Eyeliner Magnético Boomsy"
              placeholder="tracedSVG"
            />
          </div>
          <div>
            <h2>Eyeliner Magnético</h2>
            <p>
              O <strong>Eyeliner Magnético Boomsy</strong> é o melhor amigo das
              nossas pestanas magnéticas. Podes levar o nosso Eyeliner para todo
              o lado, até para o ginásio, visto ser à{" "}
              <strong>prova de água</strong> e de suor. É um eyeliner vaidoso,{" "}
              <strong>super pigmentado</strong>, que adora dar nas vistas!
            </p>
            <p>
              Se te estás a questionar se utilizar um eyeliner magnético é{" "}
              <strong>100% seguro</strong>… a resposta é SIM! Se quiseres saber
              mais sobre os ingredientes utilizados,{" "}
              <Link to="/faq/">clica aqui</Link>.
            </p>
          </div>
        </div>
        <div className="grid-2x reverse">
          <div>
            <h1>Pestanas Magnéticas</h1>
            <p>
              As <strong>Pestanas Magnéticas Boomsy</strong> foram delicadamente{" "}
              <strong>feitas à mão</strong> e são recomendadas para todos:
              vegans, rockstars, influenciadores digitais.
            </p>
            <p>
              Somos uma <strong>marca vegan</strong> (nenhum produto Boomsy é de
              origem animal) e <strong>“cruelty-free”</strong> (nada foi testado
              em animais).
            </p>
            <div style={{ marginTop: "2.4rem" }}>
              <p>
                As nossas pestanas são compostas por <strong>10 ímanes</strong>{" "}
                que garantem total fixação, até nas condições mais adversas -
                são à <strong>prova de água</strong> e até as podes usar numa
                viagem de descapotável a 120 km/h!
              </p>
              <p>
                A melhor parte? Podes usá-las vezes e vezes sem conta, visto que
                as pestanas magnéticas Boomsy dão para mais de{" "}
                <strong>35 utilizações</strong>.
              </p>
            </div>
            <div className="center">
              <AnchorLink href="#lashes" className="btn">
                Ver Modelos
              </AnchorLink>
            </div>
          </div>

          <div className="center mobile-first" style={{ padding: "4rem" }}>
            <StaticImage
              src="../images/pestanas-magneticas-boomsy.png"
              alt="Pestanas Magnéticas Boomsy"
              placeholder="tracedSVG"
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ProductGroup
