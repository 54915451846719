import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import styled from "styled-components"

const GridContainer = styled.section`
  background: #fff6f6;
  text-align: center;
  h3 {
    text-transform: uppercase;
    font-size: 3.6rem;
    font-weight: 400;
    margin-bottom: 6rem;
    strong {
      font-weight: 900;
    }
  }
  .description {
    padding: 1rem;
    font-weight: 700;
    font-size: 1.6rem;
    text-transform: uppercase;
  }
  .price {
    font-weight: 400;
    font-size: 1.6rem;
    padding-bottom: 1.6rem;
    text-transform: uppercase;
    color: #e8b795;
  }
`

const ProductGrid = () => {
  return (
    <GridContainer>
      <div className="large-container">
        <div className="center">
          <h3>
            Faz já a <strong>tua encomenda</strong>
          </h3>
        </div>
        <div className="grid-3x">
          <div>
            <StaticImage
              src="../images/Boomsy-Kit-Square.jpg"
              alt="Starter Kit Boomsy"
              width={400}
              placeholder="tracedSVG"
            />
            <div className="description">Kit Pestanas + Eyeliner</div>
            <div className="price">35€</div>
          </div>
          <div>
            <StaticImage
              src="../images/Boomsy-Lashes-Square.jpg"
              alt="Pestanas Magnéticas Boomsy"
              width={400}
              placeholder="tracedSVG"
            />
            <div className="description">Pestanas Magnéticas</div>
            <div className="price">18€</div>
          </div>
          <div>
            <StaticImage
              src="../images/Boomsy-Eyeliner-Square.jpg"
              alt="Eyeliner magnético Boomsy"
              width={400}
              placeholder="tracedSVG"
            />
            <div className="description">Eyeliner Magnético</div>
            <div className="price">22€</div>
          </div>
        </div>
        <div>
          <Link to="/loja/" className="btn">
            Visitar Loja &gt;
          </Link>
        </div>
      </div>
    </GridContainer>
  )
}

export default ProductGrid
