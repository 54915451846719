import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ProductGroup from "../components/productgroup"
import StepByStep from "../components/stepbystep"
import StarterKit from "../components/starterkit"
import ProductDetail from "../components/productdetail"
import ProductGrid from "../components/productgrid"
import InstagramFeed from "../components/instagramfeed"

const MagneticCollection = () => {
  return (
    <Layout>
      <Seo
        title="Magnetic Collection"
        description="Conhece as nossas pestanas magnéticas, compostas por 10 ímanes, que permitem mais de 35 utilizações e são à prova de água. Somos uma marca vegan e cruelty-free."
      />
      <StarterKit />
      <StepByStep title="Passo a Passo" background="#f9e7de" />
      <ProductGroup />
      <ProductDetail />
      <ProductGrid />
      <InstagramFeed />
    </Layout>
  )
}

export default MagneticCollection
